import React, { Component } from 'react';
import { connect } from 'react-redux';
class Options extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            placeholder:{
                background:null,
                score:this.props.score,
                lifes:this.props.lifes,
            }
         }
    }
    handleImg = (e) => {
        if(e.target.files[0]) {
            console.log(e.target.files[0].name)
            this.setState({ placeholder:{
                ...this.state.placeholder,
                background:e.target.files[0],
            }  });   
        }   
    }
    handleScore = e =>{
        this.setState({ placeholder:{
            ...this.state.placeholder,
            score:e.target.value
        }});
    }
    handleLifes = e =>{
        this.setState({ placeholder:{
            ...this.state.placeholder,
            lifes:e.target.value
        }});
    }

    convertObject = () =>{
        const data = this.state.placeholder;
        this.props.changeOptions(data);
        // this.props.showOptions()
    }
    render() { 
        return ( <>
            <div className="panel">
                <div className="panel-head">
                    <b>Game Options </b>
                    <button onClick={this.props.showOptions}> <i className="fa fa-times"></i> </button>
                </div>
                <div className="panel-body">
                    <div className="preview">
                        {this.state.placeholder.background === null?<i className="fa fa-image"></i>:<img src={URL.createObjectURL(this.state.placeholder.background)} />}
                        
                    </div>
                    <input type="file" accept=".png, .jpg, .jpeg"  onChange={this.handleImg} name="background" id="" className="input"/>
                    <input type="number" value={this.state.placeholder.score} placeholder="Score" onChange={this.handleScore} className="input"/>
                    {/* <input type="number" value={this.state.placeholder.lifes} placeholder="Lifes" onChange={this.handleLifes} className="input"/> */}
                    <button className="btni" onClick={this.convertObject}> Save </button>
                    <button className="btni" onClick={this.props.showOptions}> Cancel </button>
                </div>
            </div>
        </> );
    }
}
 
const mapStateToProps = (state) =>{
    return {
        addObject : state.addObject,
        score: state.game.score,
        lifes: state.game.lifes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showOptions:()=>dispatch({type:"SHOW_OPTIONS"}),
        changeOptions:(obj)=>dispatch({type:"CHANGE_OPTIONS",data:obj})
    }
} 
export default connect(mapStateToProps,mapDispatchToProps)(Options);