import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddObject from './AddObject';
import EditObject from './EditObject';
import Options from './Options';

class LeftList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            objects : [],
        }
    }


    
    render() { 
        const objectsTemplate = this.props.gameObjects.map((item,index)=>(
            <div className="object" key={index}>
                <img className="img-fluid" src={URL.createObjectURL(item.img)} alt="object"/> <span> {item.name}</span>
                <div className="dropdown float-end">
                    <button className="btn btn-sm options-btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa fa-cog"></i>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a className="dropdown-item" onClick={()=> this.props.selectObject(index)} >Edit</a></li>
                        <li><button className="dropdown-item" onClick={()=>this.props.removeGameObject(index)} >Delete</button></li>
                    </ul>
                </div>
            </div>
        ));
        return ( <>
        {this.props.selectedObject != null?<EditObject />:null}
        {this.props.options?<Options />:null}
        {this.props.addObject?<AddObject />:null}
             <div className="objects">
                    <div className="head">
                    <i className="fa fa-puzzle-piece"></i>
                    <b> PogaBox  </b>
                    
                    <button className="add-object run" onClick={this.props.runLive}> {this.props.isLive?<i className="fa fa-pause" style={{color:"#f2aa03"}}></i>:<i className="fa fa-play" style={{color:"#00cb00"}}></i>}  </button>
                <button className="run add-object" onClick={this.props.showOptions}> <i className="fa fa-cog"></i>  </button>

                    <button className="add-object" onClick={this.props.showObjectPanel}>
                        <i className="fa fa-plus"></i> 
                       
                    </button>
                    
                    </div>
                    {objectsTemplate}
                </div>

        </> );
    }
}
 
const mapStateToProps = (state) =>{
    return {
        addObject : state.gameObj.addObject,
        selectedObject : state.gameObj.selectedObject,
        gameObjects : state.gameObj.gameObjects,
        isLive:state.live.isLive,
        options:state.game.options
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showObjectPanel:()=>dispatch({type:"SHOW_OBJECT_PANEL"}),
        runLive : ()=> dispatch({type:"RUN_LIVE"}),
        showOptions : ()=> dispatch({type:"SHOW_OPTIONS"}),
        removeGameObject : (index) => dispatch({type:"REMOVE_OBJECT",index}),
        selectObject : (index) => dispatch({type:"SELECTED_OBJECT",index}),
    }
} 
export default connect(mapStateToProps,mapDispatchToProps)(LeftList);