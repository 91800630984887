import {combineReducers, createStore} from 'redux';
import ObjectReducer from './Reducers/ObjectReducer';
import LiveReducer from './Reducers/LiveReducer';
import GameReducer from './Reducers/GameReducer';

let rootReducer = combineReducers({
    gameObj: ObjectReducer,
    live: LiveReducer,
    game: GameReducer
});

const Store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export default Store;