import React, { Component } from 'react';
import { connect } from 'react-redux';
import Draggable from 'react-draggable'; // The default
import { Resizable, ResizableBox } from 'react-resizable';

class EditArea extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            objects : [...this.props.gameObjects]
         }
    }
    handleStop = (data,index) =>{
        console.log(data.x,data.y,index)
        this.props.changePosition(data.x,data.y,index);
    }
    render() { 
        const objectsTemplate = this.props.gameObjects.map((item,index) => {
            return (
                <Draggable key={index} onStop={(e,data) => this.handleStop(data,index)} defaultPosition={{x: item.x, y: item.y}} >
                    <ResizableBox width={item.width} height={item.height} minConstraints={[item.width, item.height]} maxConstraints={[item.width, item.height]}>
                        <img src={URL.createObjectURL(item.img)} />
                        
                    </ResizableBox>
                </Draggable>
            )
        });
        return ( 
            <div className="container-fluid">
              
                <div className="edit-area">
                    {this.props.background != null ?<img src={URL.createObjectURL(this.props.background)} style={{position:"absolute",top:0,left:0,width:"100%",height:"100%",zIndex:0}} />:null}
                    
                    <div className="status row text-center" style={{zIndex:1,position:"relative"}}>
                        <div className="col-6">
                        <div className="score">Score : {this.props.score}</div>
                        </div>
                        <div className="col-6">
                        {/* <div className="lifes">Lifes : {this.props.lifes}</div> */}
                        </div>
                    </div>

                    <div className="edit-objects" style={{zIndex:1,position:"relative"}}>
                        {objectsTemplate}

                    </div>

                </div> 
            </div>   
         );
    }
}
 
const mapStateToProps = (state) =>{
    return {
        gameObjects:state.gameObj.gameObjects   ,
        score:state.game.score,
        lifes:state.game.lifes,
        background:state.game.background,
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        changePosition: (x,y,index)=>dispatch({type:"CHANGE_POSITION",x,y,index})
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(EditArea);