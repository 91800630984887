import React, { Component } from 'react';
import { connect } from 'react-redux';
class AddObject extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            placeholder:{
                img:null,
                name:"",
                width:0,
                height:0
            }
         }
    }
    handleImg = (e) => {
        if(e.target.files[0]) {
            console.log(e.target.files[0].name)
            const img = new Image();
            img.src = URL.createObjectURL(e.target.files[0]);
            img.onload = () =>{
                console.log(img.width);
                this.setState({ placeholder:{
                    ...this.state.placeholder,
                    img:e.target.files[0],
                    width:img.width,
                    height:img.height,
                }  });   

            }
        }   
    }
    handleName = e =>{
        this.setState({ placeholder:{
            ...this.state.placeholder,
            name:e.target.value
        }});
    }
    handleWidth = e =>{
        this.setState({ placeholder:{
            ...this.state.placeholder,
            width:e.target.value
        }});
    }
    handleHeight = e =>{
        this.setState({ placeholder:{
            ...this.state.placeholder,
            height:e.target.value
        }});
    }

    convertObject = () =>{
        const {name,img,width,height} = this.state.placeholder;
        if(name !== '' && img !== null){
            this.props.newObject({
                name,
                img,
                width:parseInt(width),
                height:parseInt(height),
                x:0,
                y:0,
                moveDir:null,
                collisions:[]
            });
            this.props.showObjectPanel()
        }
    }
    render() { 
        return ( <>
            <div className="panel">
                <div className="panel-head">
                    <b>Add New Object</b>
                    <button onClick={this.props.showObjectPanel}> <i className="fa fa-times"></i> </button>
                </div>
                <div className="panel-body">
                    <div className="preview">
                        {this.state.placeholder.img === null?<i className="fa fa-image"></i>:<img src={URL.createObjectURL(this.state.placeholder.img)} />}
                        
                    </div>
                    <input type="file" accept=".png, .jpg, .jpeg"  onChange={this.handleImg} name="img" id="" className="input"/>
                    <input type="text" placeholder="Object Name" onChange={this.handleName} className="input"/>
                    <input type="text" placeholder="Object Width" value={this.state.placeholder.width} onChange={this.handleWidth} className="input"/>
                    <input type="text" placeholder="Object Height" value={this.state.placeholder.height} onChange={this.handleHeight} className="input"/>
                    <button className="btni" onClick={this.convertObject}> Confirm </button>
                    <button className="btni" onClick={this.props.showObjectPanel}> Cancel </button>
                </div>
            </div>
        </> );
    }
}
 
const mapStateToProps = (state) =>{
    return {
        addObject : state.addObject
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showObjectPanel:()=>dispatch({type:"SHOW_OBJECT_PANEL"}),
        newObject:(obj)=>dispatch({type:"ADD_OBJECT",newObject:obj})
    }
} 
export default connect(mapStateToProps,mapDispatchToProps)(AddObject);