import React, { Component } from 'react';
import { connect } from 'react-redux';
import CollisionPanel from './CollisionPanel';
import MovePanel from './MovePanel';
class EditObject extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            gameObjects:[...this.props.gameObj],
         }
    }

    showMovePanel = () =>{
        this.setState({ movePanel:!this.state.movePanel  });
    }
    render() { 
        const obj = this.state.gameObjects[this.props.selectedObject];
        return ( <>
        
            <div className="panel">
            {this.props.movePanel?<MovePanel item={obj} index={this.props.selectedObject}/>:null}
            {this.props.collisionPanel?<CollisionPanel item={obj} index={this.props.selectedObject}/>:null}
                <div className="panel-head">
                    <b>Edit Object</b>
                    <button onClick={this.props.hideEditObject}> <i className="fa fa-times"></i> </button>
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="preview">
                                <img src={URL.createObjectURL(obj.img)} />
                            </div>
                            <div className="text-left" style={{color:"#fff",marginBottom:10,marginLeft:20}}> {obj.name} </div>
                            <div className="dimentions">
                                <p><b>width :</b> {obj.width}px</p>
                                <p><b>height :</b> {obj.height}px</p>
                                <p><b>x :</b> {obj.x}</p>
                                <p><b>y :</b> {obj.y}</p>
                                <p><b>moveDir :</b> {obj.moveDir}</p>
                            </div>
                        </div>
                        <div className="col-md-8 row">
                            <div className="col-md-4"> 
                                <div className="action" onClick={this.props.showMove}>
                                    <i className="fa fa-shoe-prints"></i>
                                    <p>Move</p>
                                </div>
                            </div>
                            <div className="col-md-4"> 
                                <div className="action" onClick={this.props.showCollision}>
                                    <i className="fa fa-compress-alt"></i>
                                    <p>Collision</p>
                                </div>
                            </div>
                            <div className="col-md-4"> 
                                <div className="action">
                                    <i className="fa fa-hand-pointer"></i>
                                    <p>Click</p>
                                </div>
                            </div>
                            <div className="col-md-4"> 
                                <div className="action">
                                    <i className="fa fa-stopwatch"></i>
                                    <p>Animate (SOON)</p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <button className="btni" onClick={this.props.hideEditObject}> Close </button>
                </div>
            </div>
        </> );
    }
}
 
const mapStateToProps = (state) =>{
    return {
        gameObj : state.gameObj.gameObjects,
        movePanel : state.gameObj.movePanel,
        collisionPanel : state.gameObj.collisionPanel,
        selectedObject:state.gameObj.selectedObject
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showMove:()=>dispatch({type:"SHOW_MOVE_PANEL"}),
        showCollision:()=>dispatch({type:"SHOW_COLLISION_PANEL"}),
        hideEditObject:()=>dispatch({type:"HIDE_EDIT_OBJECT"}),
        newObject:(obj)=>dispatch({type:"ADD_OBJECT",newObject:obj})
    }
} 
export default connect(mapStateToProps,mapDispatchToProps)(EditObject);