const initialState = {
    gameObjects :[],
    addObject:false,
    selectedObject:null,
    movePanel:false,
    collisionPanel:false
}
const ObjectReducer = (state = initialState,action) => {
    switch (action.type) {
        case "ADD_OBJECT":
            var objectsList = [...state.gameObjects];
            objectsList.push(action.newObject);
            return {...state,gameObjects:objectsList};  
            
        case "ADD_COLLISION":
            var objectsList = [...state.gameObjects];
            console.log(objectsList[action.index])
            objectsList[action.index].collisions.push(action.obj);
            return {...state,gameObjects:objectsList};  
            
        case "REMOVE_COLLISION":
            var objectsList = [...state.gameObjects];
            console.log(objectsList[action.index])
            objectsList[action.index].collisions.splice(action.colIndex,1);
            return {...state,gameObjects:objectsList};  
            
        case "SHOW_OBJECT_PANEL":  
            var newState1 = {...state};
            newState1.addObject= !state.addObject;
            return newState1;
            
        case "SHOW_MOVE_PANEL":  
            var newState1 = {...state};
            newState1.movePanel= !state.movePanel;
            return newState1;
            
        case "SHOW_COLLISION_PANEL":  
            var newState1 = {...state};
            newState1.collisionPanel= !state.collisionPanel;
            return newState1;
        case "SELECTED_OBJECT":  
            let newState3 = {...state};
            newState3.selectedObject= action.index;
            console.log(action.index)
            return newState3;
        case "HIDE_EDIT_OBJECT":  
            let newState4 = {...state};
            newState4.selectedObject= null;
            console.log(action.index)
            return newState4;
        case "CHANGE_POSITION":
            var objectsList = [...state.gameObjects]
            objectsList[action.index].x = action.x;
            objectsList[action.index].y = action.y;
            console.log(objectsList[action.index]);
            return {...state,gameObjects:[...objectsList]}
        case "CHANGE_MOVE_DIR":
            var objectsList = [...state.gameObjects]
            objectsList[action.index].moveDir = action.dir;
            console.log(objectsList[action.index]);
            return {...state,gameObjects:[...objectsList]}
        case "REMOVE_OBJECT":
            var objectsList = [...state.gameObjects];
            objectsList.splice(action.index,1);
            console.log(objectsList);
            return {...state,gameObjects:objectsList}
        default:
            return state;
    }
}

export default ObjectReducer;