import React, { Component } from 'react';
import { connect } from 'react-redux';
class CollisionPanel extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            currentSelect:null,
            currentAction:null,
            collisions:[]
        }
    }

    
    select = (index) =>{
        this.setState({ currentSelect:index  });
    }
    
    action = (index) =>{
        this.setState({ currentAction:index  });
    }

    addCollesion = () => {
        console.log(0)
        if(this.state.currentSelect !== null && this.state.currentAction !== null){

            this.props.addColl({selector:this.state.currentSelect,action:this.state.currentAction},this.props.index)
        }
    }
    render() { 
        const {index: i} = this.props;
        const item = this.props.gameObjects[i];
        const objectsTemplate = this.props.gameObjects.map((item,index)=>{
            if(i === index){
                return false;
            }
            var classess = '';
            if(index == this.state.currentSelect){
                classess="action-active"
            }
            return(
            <img key={index} className={classess} onClick={()=>this.select(index)} src={URL.createObjectURL(item.img)} alt=""/>
            )
         });

         const collisionsTemplate = item.collisions.map((item,index)=>{
            var actionName = '';
            switch (item.action) {
                case 0:
                    actionName="Hide Me"
                case 1:
                    actionName="+Score"
                case 2:
                    actionName="-Score"
                case 3:
                    actionName="Stop [" +this.props.gameObjects[item.selector].name+"]"
            }
            return(
            <tr>
                <td>{index + 1}</td>
                <td> <img style={{marginTop:10}} src={URL.createObjectURL(this.props.gameObjects[item.selector].img)} alt=""/> {this.props.gameObjects[item.selector].name}</td>
                <td>{actionName}</td>
                <td><i onClick={()=> this.props.removeColl(index,i)} className="fa fa-trash"></i></td>
            </tr>
         )});
        return ( <>
            <div className="panel" style={{width:400}}>
                <div className="panel-head">
                    <b>Collision Options </b>
                    <button onClick={this.props.showCollision}> <i className="fa fa-times"></i> </button>
                </div>
                <div className="panel-body light">
                    <div className="row">
                        <div className="col-md-6">
                            <p><b>Select Object</b></p>
                            <div className="objects-list">
                                {objectsTemplate}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <p><b>Select Action</b></p>
                            <div className="objects-list">
                                <div className={this.state.currentAction == 0?"object-action action-active":"object-action"} onClick={()=>this.action(0)}>0 - Hide Me </div>
                                <div className={this.state.currentAction == 1?"object-action action-active":"object-action"} onClick={()=>this.action(1)}>1 - +Score </div>
                                <div className={this.state.currentAction == 2?"object-action action-active":"object-action"} onClick={()=>this.action(2)}>2 - -Score </div>
                                <div className={this.state.currentAction == 3?"object-action action-active":"object-action"} onClick={()=>this.action(3)}>3 - Stop [{item.name}]</div>
                            </div>
                        </div>
                        <div className="col-12">
                            <p><b> Collisions </b> <div className="float-end"> <button className="btni" onClick={this.addCollesion}><i className="fa fa-plus"></i> add</button> </div></p>
                            <div className="objects-list">
                                <table className="table text-center">
                                    <tr>
                                        <td><b> # </b></td>
                                        <td><b> Object </b></td>
                                        <td><b> Action Index </b></td>
                                        <td><b>  Remove </b></td>
                                    </tr>
                                    {collisionsTemplate}
                                </table>
                            </div>
                        </div>
                    </div>
                    <hr style={{border:"1ox solid #ccc"}}/>
                    <button className="btni" onClick={this.props.showCollision}> Save </button>
                </div>
            </div>
        </> );
    }
}
 
const mapStateToProps = (state) =>{
    return {
        gameObjects : state.gameObj.gameObjects,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showCollision:()=>dispatch({type:"SHOW_COLLISION_PANEL"}),
        changeMoveDir:(dir,index)=>dispatch({type:"CHANGE_MOVE_DIR",dir,index}),
        addColl:(obj,index)=>dispatch({type:"ADD_COLLISION",obj,index}),
        removeColl:(colIndex,index)=>dispatch({type:"REMOVE_COLLISION",colIndex,index})
    }
} 
export default connect(mapStateToProps,mapDispatchToProps)(CollisionPanel);