import React, { Component } from 'react';
import { connect } from 'react-redux';

class Live extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            gameObj :[...this.props.gameObj]
         }
    }
    componentWillMount = function() {
    window.addEventListener('keydown', this.moveObject);
  }

  componentWillUnmount = function() {
    window.removeEventListener('keydown', this.moveObject);
  }
    moveObject = (e) => {
        var gameObjects = [...this.state.gameObj];
        gameObjects.map((item,index)=>{
            var newItem = {...item};
            var lastX = item.x;
            var lastY = item.y;
            if(item.moveDir !== null){
                if(item.moveDir == 0 || item.moveDir == 2){
                    if(e.key == "ArrowUp"){
                        newItem.y -= 10;
                        gameObjects[index] = {...newItem};
                    }else if(e.key == "ArrowDown"){

                        item.y += 10;
                        gameObjects[index] = {...item};
                    }
                   
                }  
                if(item.moveDir == 1 || item.moveDir == 2){
                    if(e.key == "ArrowLeft"){
                        newItem.x -= 10;
                        gameObjects[index] = {...newItem};
                    }else if(e.key == "ArrowRight"){

                        item.x += 10;
                        gameObjects[index] = {...item};
                    }
                   
                }  

                item.collisions.map((col,colIndex)=>{
                    var colItem = gameObjects[col.selector] ;

                    if(item.x < (colItem.x + colItem.width) &&
                        (item.x + item.width) > colItem.x &&
                        item.y < colItem.y + colItem.height &&
                        (item.y + item.height) > colItem.y) {
                            console.log(0)
                            if(col.action === 0){
                                // console.log();
                                let colll = [...gameObjects[index].collisions];
                                colll.splice(colIndex,1)
                                gameObjects[index].collisions = [...colll];
                                gameObjects.splice(col.selector,1);
                                // gameObjects[index].collisions.splice(colIndex,1);
                                
                            }else if(col.action === 1){
                                this.props.changeScore(10);
                                // let colll2 = [...item.collisions];
                                // colll2.splice(colIndex,1)
                                // console.log(colll2);

                            }else if(col.action === 2){
                                this.props.changeScore(-10);
                                // let colll = [...gameObjects[index].collisions];
                                // colll.splice(colIndex,1)
                            }else if(col.action === 3){
                                item.x = lastX;
                                item.y = lastY;
                                gameObjects[index] = {...item};
                            }
                            //TODO - create actions
                    }
                  
                    // if(item.x >= (colItem.x - 200) ){
                    //     console.log(col.selector)
                    //     console.log(colItem.x,item.x);
                    // }
                });
                this.setState({ gameObj:gameObjects  });
            }
           
        })
    }
    render() { 
        const objectsTemplate = this.state.gameObj.map((item,index)=>(
            <img key={index} style={{position:"absolute",top:0,left:0,height:item.height+"px",width:item.width+"px",transform:`translate(${item.x}px, ${item.y}px)`}} src={URL.createObjectURL(item.img)} alt=""/>
        ));
        return ( <>
            <div className="container-fluid">
                <div className="edit-area">
                    {this.props.background != null ?<img src={URL.createObjectURL(this.props.background)} style={{position:"absolute",top:0,left:0,width:"100%",height:"100%",zIndex:0}} />:null}
              <span style={{background:"green",color:"#fff",position:"absolute",bottom:"100%",padding:"0px 20px"}}> Live </span>
                    <div className="status row text-center" style={{position:"relative"}}>
                        <div className="col-6">
                        <div className="score">Score : {this.props.score}</div>
                        </div>
                        <div className="col-6">
                        {/* <div className="lifes">Lifes : {this.props.lifes}</div> */}
                        </div>
                    </div>

                    <div className="edit-objects" style={{position:"relative"}}>
                        {objectsTemplate}
                    </div>

                </div> 
            </div>
        </> );
    }
}
 
const mapStateToProps = state => {
    return {
        gameObj:state.gameObj.gameObjects,
        score:state.game.score,
        lifes:state.game.lifes,
        background:state.game.background,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeScore : (value)=>dispatch({type:"SCORE",value})
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Live);