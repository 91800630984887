let initialState = {
    score:0,
    lifes:3,
    background:null,
    options:false
}

const GameReducer = (state = initialState,action) => {
    switch (action.type) {
        case "SHOW_OPTIONS":
            var newState = {...state};
            newState.options = !state.options;
            return newState;
    
        case "SCORE":
            var newState = {...state};
            console.log(action);
            newState.score = state.score + action.value;
            return newState;
    
        case "CHANGE_OPTIONS":
            var newState = {...state};
            newState.score = action.data.score;
            if(action.data.background != null){
                newState.background = action.data.background;
            }
            newState.lifes = action.data.lifes;
            console.log(action);
            return newState;
    
        default:
            return state;
    }

}

export default GameReducer