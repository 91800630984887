let initialState = {
    isLive:false
}

const LiveReducer = (state = initialState,action) => {
    switch (action.type) {
        case "RUN_LIVE":
            var newState = {...state};
            newState.isLive = !state.isLive;
            return newState;
    
        default:
            return state;
    }

}

export default LiveReducer