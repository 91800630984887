import React, { Component } from 'react';
import { connect } from 'react-redux';
class MovePanel extends Component {
    constructor(props) {
        super(props);
        this.state = { 
        }
    }
  
    render() { 
        const {index} = this.props;
        const item = this.props.gameObjects[index]
        return ( <>
            <div className="panel">
                <div className="panel-head">
                    <b>Move Options </b>
                    <button onClick={this.props.showMove}> <i className="fa fa-times"></i> </button>
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className={item.moveDir == null?"action action-active":"action"} onClick={()=> this.props.changeMoveDir(null,index)} style={{padding:3,paddingTop:10}}>
                                <i className="fa fa-times-circle"></i>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={item.moveDir == 0?"action action-active":"action"} onClick={()=> this.props.changeMoveDir(0,index)} style={{padding:3,paddingTop:10}}>
                                <i className="fa fa-arrows-alt-v"></i>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={item.moveDir == 1?"action action-active":"action"} onClick={()=> this.props.changeMoveDir(1,index)} style={{padding:3,paddingTop:10}}>
                                <i className="fa fa-arrows-alt-h"></i>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={item.moveDir == 2?"action action-active":"action"} onClick={()=> this.props.changeMoveDir(2,index)} style={{padding:3,paddingTop:10}}>
                                <i className="fa fa-arrows-alt"></i>
                            </div>
                        </div>
                    </div>
                    <hr style={{border:"1ox solid #ccc"}}/>
                    <button className="btni" onClick={this.props.showMove}> Save </button>
                </div>
            </div>
        </> );
    }
}
 
const mapStateToProps = (state) =>{
    return {
        gameObjects : state.gameObj.gameObjects,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showMove:()=>dispatch({type:"SHOW_MOVE_PANEL"}),
        changeMoveDir:(dir,index)=>dispatch({type:"CHANGE_MOVE_DIR",dir,index})
    }
} 
export default connect(mapStateToProps,mapDispatchToProps)(MovePanel);