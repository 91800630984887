import { connect } from "react-redux";
import EditArea from "./Components/Editor/EditArea";
import LeftList from "./Components/Editor/Lists/LeftList";
import Live from "./Components/Live";

function App(props) {
  return (
    <div className="App">
     <LeftList /> 
     {props.isLive?<Live />:<EditArea />}
     
     
    </div>
  );
}

const mapStateToProps = state =>{
  return{
    isLive : state.live.isLive
  }
}
export default connect(mapStateToProps)(App);
